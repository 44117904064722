import { NavStrings, UrlConstants } from "../constants";
import { useNavigate, useParams } from "react-router-dom";

import { AiOutlineArrowLeft } from "react-icons/ai";
import React from "react";
import { SEO } from "../components";

const BlogDetail: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const navigate = useNavigate();
  const UrlLink = UrlConstants.base_url.concat(NavStrings.BLOGDETAIL);

  const blogContent: any = {
    "progressive-contact-lenses": {
      title: "Everything You Need to Know About Progressive Contact Lenses",
      content: `Are you struggling with presbyopia, a age-related condition that affects your ability to focus on close objects? Do you frequently change between reading glasses and distance glasses? If so, progressive contact lenses may be the solution you've been looking for.
                <br>
                <strong>What are Progressive Contact Lenses?</strong>
                <br>
                Progressive contact lenses are designed to correct presbyopia. They have a gradual transition between different powers, allowing you to see clearly at multiple distances. Unlike bifocal lenses, which have a visible line separating the near and distance segments, progressive lenses have a smooth, seamless transition.
                <br>
                <strong>Benefits of Progressive Contact Lenses</strong>
                <br>
                Progressive contact lenses offer several benefits, including:
                <br>
                1. Convenience: With progressive contact lenses, you don't have to change frequently between different pairs of glasses.
                <br>
                2. Improved vision: Progressive contact lenses provide clear vision at multiple distances, reducing eye strain and fatigue.
                <br>
                3. Comfort: Progressive contact lenses are designed to be comfortable and easy to wear.
                <br>
                4. Cosmetic appeal: Progressive contact lenses are a great alternative to reading glasses, which can be perceived as a sign of aging.
                <br>
                <strong>Types of Progressive Contact Lenses</strong>
                <br>
                There are several types of progressive contact lenses available, including:
                <br>
                1. Soft progressive contact lenses: These lenses are made of a soft, flexible material and are designed for daily wear.
                <br>
                2. Rigid gas permeable (RGP) progressive contact lenses: These lenses are made of a more rigid material and are designed for daily wear.
                <br>
                3. Hybrid progressive contact lenses: These lenses combine a soft outer ring with a rigid center.
                <br>
                <strong>Where to Buy Progressive Contact Lenses in Hyderabad</strong>
                <br>
                If you're looking for high-quality progressive contact lenses in Hyderabad, look no further than Clariviz. Clariviz is a leading provider of contact lenses, including progressive contact lenses, from top manufacturers. Our experienced team will help you get the perfect lenses to meet your needs and budget.
                <br>
                Progressive contact lenses are a convenient and effective solution for people struggling with presbyopia. With Clariviz, you can trust that you're getting high-quality lenses from top manufacturers. Reach Clariviz today to learn more and schedule an appointment.`,
    },
  };

  const blog = blogContent[name || ""];

  if (!blog) {
    return (
      <section className="w-[100%] max-w-screen-2xl mx-auto">
        <SEO
          title={`Blog - ${name}`}
          description="Explore career opportunities at Ben Franklin. Learn about our values, culture of learning and rewards, and the benefits of joining our dynamic and diverse team."
          keywords="Ben Franklin careers, optical retail jobs, career opportunities, company values, work culture, employee rewards, learning and development"
          name={`Blog - ${name}`}
          type="website"
          canonicallink={UrlLink}
        />
        <div
          className="mx-auto px-4 py-4 flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <AiOutlineArrowLeft size={30} className="text-black text-2xl mr-2" />
          <span className="text-black text-lg">Back</span>
        </div>
        <div className="flex justify-center items-center h-screen text-gray-600">
          <p className="text-xl">Blog not found</p>
        </div>
      </section>
    );
  }

  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title={`Blog - ${name}`}
        description="Explore career opportunities at Ben Franklin. Learn about our values, culture of learning and rewards, and the benefits of joining our dynamic and diverse team."
        keywords="Ben Franklin careers, optical retail jobs, career opportunities, company values, work culture, employee rewards, learning and development"
        name={`Blog - ${name}`}
        type="website"
        canonicallink={UrlLink}
      />
      <div
        className="mx-auto px-4 py-4 flex items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <AiOutlineArrowLeft size={30} className="text-black text-2xl mr-2" />
        <span className="text-black text-lg">Back</span>
      </div>

      <section className="max-w-5xl mx-auto px-4 py-12">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200">
          <header className="bg-primary p-6 flex items-center">
            <h1 className="text-4xl font-semibold text-white">{blog.title}</h1>
          </header>
          <article
            className="p-8 text-gray-700 leading-loose"
            dangerouslySetInnerHTML={{ __html: blog.content }}
          />
        </div>
      </section>
    </section>
  );
};

export default BlogDetail;
