import { NavStrings, UrlConstants } from "../constants";

import EgDigital20 from "../assets/images/ben-digital-pal/BEN DIGITAL 1.0.webp";
import { Link } from "react-router-dom";
import React from "react";
import { SEO } from "../components";

const BlogList: React.FC = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.BLOG);
  const blogs = [
    {
      id: 1,
      title: "Everything You Need to Know About Progressive Contact Lenses",
      img: EgDigital20,
      name:"progressive-contact-lenses"
    }
  ];

  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Blogs - Clariviz"
        description="Explore career opportunities at Ben Franklin. Learn about our values, culture of learning and rewards, and the benefits of joining our dynamic and diverse team."
        keywords="Ben Franklin careers, optical retail jobs, career opportunities, company values, work culture, employee rewards, learning and development"
        name="Blogs"
        type="website"
        canonicallink={UrlLink}
      />
    <div className="w-full max-w-screen-2xl mx-auto px-4 py-10" >
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Blogs</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {blogs.map((blog) => (
          <div
            key={blog.id}
            className="bg-white shadow-lg rounded-lg p-6 border border-gray-200 flex flex-col items-center"
          >
            <img src={blog.img} alt={blog.title} className="w-full h-40 object-cover mb-4 rounded" />
            <Link to={`/blog/${blog.name}`} className="text-lg font-semibold text-blue-600 hover:underline text-center">
              {blog.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
    </section>
  );
};

export default BlogList;
