const NavStrings = {
  HOME: "/",
  BLOG: "/blog-list",
  BLOGDETAIL: "/blog/:name",
  CLARIVIZBYBENFRANKLIN: "/about-us-clariviz-by-ben-franklin",
  LENSMANUFACTURINGUNIT: "/about-us-lens-manufacturing-unit",
  CONTACTUS: "/contact-us",
  STORELOCATOR: "/store-locator",
  CLARIVIZLENSES: "/clariviz-lenses",
  ZSERIESDS: "/clariviz-z-series-ds",
  ZSERIES20: "/clariviz-z-series-2.0",
  ZSERIES10: "/clariviz-z-series-1.0",
  DIGIPRO20: "/clariviz-digi-pro-2.0",
  DIGIPRO10: "/clariviz-digi-pro-1.0",
  PROVIEW20: "/clariviz-pro-view-2.0",
  PROVIEW10: "/clariviz-pro-view-1.0",
  CLEARDRIVEPAL: "/clariviz-clear-drive-pal",
  CLARIVIZOFFICE: "/clariviz-office",
  PIXELDIGI: "/pixel-digi",
  QUICHROMES: "/clariviz-quichromes",
  CLARIVIZSINGLEVISION: "/clariviz-single-vision",
  CLEARDRIVESV: "/clariviz-clear-drive-sv",
  RXBIFOCAL: "/clariviz-rx-bi-focal",
  BENDIGITALPAL: "/ben-digital-pals",
  LENSTECHNOLOGIES: "/lens-technologies",
  ULTIPROUVCOATING: "/ultiprouv-coating",
  ULTIPROBLUECOATING: "/ultipro-blue-coating",
  HERCUECOATING: "/hercue-coating",
  LENSCOLOUR: "/lens-colour",
  LENSPERSONALIZATION: "/lens-personalization",
  PRIVACYPOLICY: "/privacy-policy",
  COOKIEPOLICY: "/cookie-policy",
  CAREERS: "/careers",
};

export default NavStrings;
